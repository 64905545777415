import React from 'react';
import './Aboutus.css';

const Aboutus = () => {
    return (
        <div id="aboutUs">
            <div className="row p-0 navyblue-bg">
                <div className='col p-0' style={{ paddingTop: '3%', backgroundColor: "white" }}>
                    <p className='TitleredText'>ABOUT US</p>
                    <p className='SubtitleText ' style={{ paddingBottom: "3%" }}>About Us</p>
                </div>
                <div className='row p-0'>
                    <div className='col p-0'>
                        <img src={require('../../img/bg/BG_white_about_us.png')}
                            width="100%"
                            height="100%"
                        /></div>
                </div>

                <div className='row bgredaboutus' style={{ paddingLeft: '0', paddingRight: '0' }}>
                    <div className='col d-flex justify-content-center' style={{ paddingLeft: '0', paddingRight: '0' }}>
                        <p className='detailtext ' style={{ textAlign: 'center', paddingBottom: '10%', width: '70%' }}>
                            บริษัท เอ เดฟ โซลูชั่น พลัส จำกัด เป็น Software House
                            ที่พัฒนาซอฟต์แวร์ตามความต้องการของลูกค้า ทั้งในรูปแบบของ<span style={{ color: 'red' }}>เว็บแอปพลิเคชั่น</span> (Web Application) และ <span style={{ color: 'red' }}>แอปพลิเคชั่นบนมือถือ</span> (Mobile Application) 
                            โดยเรามีทีมนักพัฒนาทั้ง Frontend Developer และ Backend Developer
                            ที่พร้อมจะปรับเปลี่ยนให้องค์กรของท่าน ก้าวสู่การเปลี่ยนแปลงในยุค 4.0
                            ได้อย่างมีประสิทธิภาพ</p>
                    </div>
                </div>

                <div className='rectangle-bg6 p-0'>
                    <div className='row justify-content-center' style={{ paddingTop: '0%', }}>
                        <div className='col-lg-4 col-md-6 col-12' >
                            <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column", marginLeft: "auto" }}>
                                <div className='row'>
                                    <div className='col'>
                                        <center><div className="bgiconinbox2">
                                            <img className='iconinbox' src={require('../../img/icon/weareadev.png')}></img>
                                        </div></center>
                                    </div>
                                </div>
                                <p className="topicText">We are ADEV</p>
                                <div style={{ width: "92%" }}>
                                    เราคือผู้พัฒนาด้านนวัตกรรม
                                    ที่จะปลุกพลังไอเดียของคุณให้เป็นการแก้ปัญหา
                                    ระดับนานาชาติ ผู้ให้บริการด้านไอทีที่ราคาดีที่สุด
                                    เป้าหมายของเราคือนำเสนอเพื่อความพึงพอใจ
                                    ของลูกค้า 100%
                                </div>
                            </p>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12'>
                            <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <div className='row'>
                                    <div className='col'>
                                        <center><div className="bgiconinbox2">
                                            <img src={require('../../img/icon/whoarewe.png')} style={{paddingTop:"20px"}}></img>
                                        </div></center>
                                    </div>
                                </div>
                                <p className="topicText">Who Are We</p>
                                <div style={{ width: "92%" }}>
                                    ADEV คือบริษัทมืออาชีพที่ให้บริการด้านไอทีอย่าง
                                    ครบวงจรมีความเชียวชาญด้านพัฒนาระบบ
                                    ตามแบบที่คุณกำหนดเอง พัฒนา ผสมผสาน
                                    สร้างคำสั่ง เพื่อแก้ปัญหาในทุก Platform
                                    จัดการธุรกิจทุกส่วนของคุณด้วย Software
                                    การจัดการอย่างมีประสิทธิภาพ
                                </div></p>
                        </div>
                    </div>

                    <div className='row justify-content-center bgredaboutus2'>
                        <div className='col-lg-4 col-md-6 col-12'>
                            <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column", marginLeft: "auto" }}>
                                <div className='row '>
                                    <div className='col'>
                                        <center><div className="bgiconinbox2">
                                            <img className='iconinbox' src={require('../../img/icon/whatwedo.png')}></img>
                                        </div></center>
                                    </div>
                                </div>
                                <p className="topicText">What We Do</p>
                                <div style={{ width: "92%" }}>
                                    ผู้เชี่ยวชาญของเราสามารถใช้ IT Function
                                    เพื่อให้คำแนะนำ ให้บริการและยกระดับธุรกิจ
                                    ของคุณพันธกิจของเราคือปลดปล่อยศักยภาพของ
                                    ลูกค้า โดยนำเอาเทคโนโลยีเข้ามาเป็นตัวช่วย
                                    เพื่อประโยชน์สูงสุดของลูกค้า</div></p>
                        </div>

                        <div className='col-lg-4 col-md-6 col-12'>
                            <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                                <div className='row '>
                                    <div className='col'>
                                        <center><div className="bgiconinbox2">
                                            <img className='iconinbox' src={require('../../img/icon/whychooseus.png')}></img>
                                        </div></center>
                                    </div>
                                </div>
                                <p className="topicText">Why Choose Us</p>
                                <div style={{ width: "92%" }}>
                                    เรามุ่งมั่นที่จะสร้างประสบการณ์ที่ดีที่ช่วยแก้ปัญหา
                                    ให้กับลูกค้าของเราเราให้บริการบริษัทชั้นนำด้วย
                                    กลยุทธ์การพัฒนาและแก้ปัญหา ขับเคลื่อนด้วยทีม
                                    พลังคนรุ่นใหม่ที่มีความเป็นมืออาชีพ เราตั้งเป้า
                                    ที่จะเสนอความพึงพอใจอย่างสูงสุดให้กับลูกค้า</div></p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Aboutus