import React from 'react';
import './Contactus.css';

const Contactus = () => {
    return (
        <div id="contectUs">
            <div className="row p-0 navyblue-bg">
                <div className='col p-0' style={{ paddingTop: '3%', backgroundColor: "white" }}>
                    <p className='TitleredText'>CONTACT US</p>
                    <p className='SubtitleText ' style={{ paddingBottom: "3%" }}>Contact us</p>
                </div>
                <div className='row p-0'>
                    <div className='col p-0' style={{ paddingbottom: '3%' }}>
                        <img src={require('../../img/bg/BG_WHITE_CONTACT US2.png')}
                            width="100%"
                            height="100%"
                        /></div>
                </div>

                <div className='row bgredWeWouldBeHappy p-0'>
                    <div className='row md-4' style={{ paddingTop: '10%', paddingBottom: '15%' }}>
                        <div className='col'>
                            <p className='headingIncontactpage'>We Would Be Happy To Assist You</p>
                        </div>
                    </div>
                </div>

                <div className='row rectangle-bg7'>
                    <div className='col' style={{ marginTop: '13%', marginBottom: '17%' }}>
                        <a href='https://www.google.co.th/maps/place/ADEV+Solutions+Plus/@13.8613275,100.4642095,14.66z/data=!4m12!1m6!3m5!1s0x30e29be516814fbb:0x6f3e5f07231db336!2sADEV+Solutions+Plus!8m2!3d13.8573235!4d100.4621827!3m4!1s0x30e29be516814fbb:0x6f3e5f07231db336!8m2!3d13.8573235!4d100.4621827?hl=en&authuser=0' target="_blank">
                            <img src={require('../../img/map.png')}
                            width="80%"
                            style={{ display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'}}
                        /></a>
                    </div>
                </div>

                    <div className='d-flex justify-content-center rectangle-bg8'>
                        <div className="row boxsendmassage">
                            <div className="col-md-6 bgredwewouldlove " style={{ paddingTop: '0', paddingBottom: '20%', paddingLeft: '0' }}>
                                <p className='d-flex justify-content-center HeadinginBox' style={{ paddingTop: '14%' }}>We’d Love To Hear<br /> About Your Project</p>
                            </div>
                            <div className="col-md-6 boxsendmassageSmall" style={{ paddingTop: '5%', paddingBottom: '3%', paddingLeft: '0' }}>
                                <form>
                                    <div className='row boxCenter' style={{ paddingBottom: '5%' }}>
                                        <div className='col-10 col-md-8 col-sm-10 col-lg-7'>
                                            <input type="email" class="form-control" id="" placeholder="Name" />
                                        </div>
                                    </div>
                                    <div className='row boxCenter' style={{ paddingBottom: '5%' }}>
                                        <div className='col-10 col-md-8 col-sm-10 col-lg-7'>
                                            <input type="email" class="form-control" id="" placeholder="Phone Number" />
                                        </div>
                                    </div>
                                    <div className='row boxCenter' style={{ paddingBottom: '5%' }}>
                                        <div className='col-10 col-md-8 col-sm-10 col-lg-7'>
                                            <input type="email" class="form-control" id="" placeholder="E-mail" />
                                        </div>
                                    </div>
                                    <div className='row boxCenter' style={{ paddingBottom: '5%' }}>
                                        <div className='col-10 col-md-11 col-sm-10 col-lg-11'>
                                            <textarea class="form-control" id="FormControlTextarea" rows="4" placeholder="Enter your message here..."></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col boxCenter" style={{ right: 'auto' }}>
                                            <button type="button" className="btn redbuttonCpage ">Send Message  <img src={require('../../img/icon/sendmessage.png')} style={{marginLeft:'10px'}}></img></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

            </div>
        </div>
    )
}

export default Contactus