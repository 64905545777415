import React from 'react'
import './Ourservice.css'

const Ourservice = () => {
    return (
        <div id="ourService">
            <div className="row justify-content-between navyblue-bg">
                <div className='row p-0 rectangle-bg2 rowService1' style={{ height: '360px' }}>
                    <div className='col md-auto d-flex'>
                        <p className='bigredText'>OUR SERVICE</p>
                        <p className='titlepageText '>Our Service</p>
                    </div>
                </div>

                <div className='row p-0 rowService2' style={{ height: '430px' }}>
                    <div className='col-md-12 sm-6 bgredservice textDetail' style={{ textAlign: 'center', height: '500px' }}>
                        พัฒนาระบบออนไลน์ เป็นบริการหลัก <br />งานอื่นๆ ที่ช่วยให้ระบบทำงานได้อย่างมีประสิทธิภาพ คืองานของเรา
                    </div>
                </div>

                <div className="row rectangle-bg3 p-0" >
                    <div className="col-md-5 textpadding1 boxtext">
                        <div>
                            <p className='topicText'>Web Design</p>
                            <p className="normalThaiText">เราเป็นผู้ช่วยคุณในการขยายธุรกิจ เรานำเสนอ
                                การให้บริการด้าน Web Design อย่างครบครัน
                                ทั้ง Web Portal, Micro Website, Flash Website,
                                Static Website และ Dynamic Website ด้วยทีม
                                ผู้เชี่ยวชาญด้าน Search Engine เราใช้เทคนิคใน
                                การสร้าง SEO เพื่อให้ Website ของคุณอยู่ในอันดับที่ดี
                                และเพิ่มจำนวนผู้เข้าชมให้แก่ Website ของคุณอย่างสูงสุด</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 d-flex justify-content-center imgpadding1">
                        <span>
                            <img className="imginbox" src={require('../../img/ux-indonesia-qC2n6RQU4Vw-unsplash.jpg')}
                                width="100%"
                                height="100%"
                            />
                        </span>

                    </div>
                </div>

                <div className="row p-0 rowReverseService" >
                    <div className="col-lg-7 col-md-12 d-flex justify-content-center imgpadding2">
                        <span>
                            <img className="imginbox" src={require('../../img/joshua-aragon-BMnhuwFYr7w-unsplash.jpg')}
                                width="100%"
                                height="100%"
                            />
                        </span>
                    </div>
                    <div className="col-md-5 boxtext2 textpadding2">
                        <div>
                            <p className='topicText'>App Development</p>
                            <p className="normalThaiText " style={{ fontWeight: "100"}}><span style={{ color: 'red' }}>A</span>DEV ให้บริการด้านพัฒนาระบบ Mobile Software<br />
                                ทั้ง iOS, Android และอื่นๆ การพัฒนาที่มากกว่า
                                Responsive สามารถตรวจสอบการประเมินตัวชี้วัด
                                และเป็นมิตรต่อผู้ใช้ให้เราช่วยเพิ่มประสิทธิภาพให้ธุรกิจ
                                ของคุณด้วยนักพัฒนาระบบ Mobile App ชั้นนำของเรา
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row rectangle-bg4 p-0" >
                    <div className="col-md-5 textpadding3 boxtext2" >
                        <div>
                            <p className='topicText'>Analytics</p>
                            <p className="normalThaiText">
                                เราให้ความสำคัญและเข้าใจในจุดประสงค์ของการสำรวจ
                                ความต้องการทางด้านข้อมูลของลูกค้ากลุ่มเป้าหมายของคุณ
                                และค้นหาข้อมูลที่คุณต้องการเพื่อให้คุณทำการตัดสินใจ
                                ด้านกลยุทธ์อย่างมีประสิทธิภาพเราช่วยองค์กรของคุณ
                                ให้ประสบความสำเร็จเหนือคู่ต่อสู้มีประสิทธิผลคุ้มค่าใช้จ่าย
                                บริหารความเสี่ยง สอดส่องข้อกฎเกณฑ์ต่างๆ และเสริมสร้าง
                                ความพึงพอใจในการบริการ
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-12 d-flex justify-content-center imgpadding3">
                        <span>
                            <img className="imginbox" src={require('../../img/path-digital-tR0jvlsmCuQ-unsplash.jpg')}
                                width="100%"
                                height="100%"
                            />
                        </span>
                    </div>
                </div>

                <div className="row p-0 rowReverseService" >
                    <div className="col-lg-7 col-md-12 d-flex justify-content-center imgpadding4">
                        <span>
                            <img className="imginbox" src={require('../../img/jason-goodman-bzqU01v-G54-unsplash.jpg')}
                                width="100%"
                                height="100%"
                            />
                        </span>

                    </div>
                    <div className="col-md-5 sm-4 boxtext2 textpadding4">
                        <div><p className='topicText'>Perfection in all aspects</p>
                            <p className="normalThaiText" style={{ fontWeight: "100"}}><span style={{ color: 'red' }}>A</span>
                                DEV ช่วยให้คุณสื่อสารแบรนด์ของคุณให้อยู่ในใจของลูกค้า
                                เราเชี่ยวชาญในด้านการวางกลยุทธ์การตลาดที่จะช่วยให้ธุรกิจ
                                ของคุณเติบโตอย่างต่อเนื่องเราสนับสนุน Startup และ
                                SME ช่วยให้ธุรกิจของคุณก้าวไปอีกระดับ e-Commerce
                                เราทำงานเป็นทีมเพื่อส่งต่อคุณภาพการแก้ปัญหาด้าน
                                eCoomerceที่จะช่วยให้ธุรกิจของคุณโตขึ้น
                                และประสบความสำเร็จในตลาด Online เราพร้อมยกระดับ
                                ธุรกิจของคุณให้ไปสู่ภูมิภาคสากลด้วยการจัดการที่
                                สมบูรณ์แบบและก้าวล้ำสร้างและทำการตลาดด้วยดีไซเนอร์
                                และนักการตลาดมือฉมังของเรา
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Ourservice