import React from 'react'
import './Ourclients.css'

const Ourclients = () => {
    return (
        <div id="ourClients">
            <div className="row justify-content-between navyblue-bg">
                <div className='row p-0 rectangle-bg5'>
                    <div className='col md-auto d-flex' style={{ paddingTop: '3%' }} >
                        <p className='bigredText'>OUR CLIENTS</p>
                        <p className='titlepageText ' style={{ paddingBottom: "5%" }}>Our Clients</p>
                    </div>

                    <div className='row bgredOurclient p-0' >
                        <div className='col p-0' >

                            {/*row1 */}
                            <div className="row p-0" >
                                <div className='col d-flex justify-content-center' style={{ textAlign: "center" }}>
                                    <div className='col p-0 m-0 col-sm-6' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/Scg.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/loft.png')}
                                                width="30%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/logo_PTT_3.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/meiji.png')}
                                                width="45%" style={{ paddingTop: "6%" }}
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/logo_icon.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/*row2 */}
                            <div className="row p-0" >
                                <div className='col d-flex justify-content-center' style={{ textAlign: "center" }}>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/Logo_SPW.png')}
                                                width="40%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/falcon.png')}
                                                width="40%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/ananda.png')}
                                                width="60%" style={{ paddingTop: "3%" }}
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/JSP.png')}
                                                width="70%" style={{ paddingTop: "3%" }}
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/krungsri.png')}
                                                width="60%"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/*row3 */}
                            <div className="row p-0" >
                                <div className='col d-flex justify-content-center' style={{ textAlign: "center" }}>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/ตรากรมสรรพสามิต.png')}
                                                width="35%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/The-Agent.png')}
                                                width="60%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/sansiri-public-Logo.png')}
                                                width="60%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/MOCAP_03TH.png')}
                                                width="40%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/one.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/*row4 */}
                            <div className="row p-0 bgredOurclient2" >
                                <div className='col p-0 d-flex justify-content-center bgredOurclient2' style={{ marginBottom: "12%", textAlign: "center" }}>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/logo_acfs.png')}
                                                width="40%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/anti-money.png')}
                                                width="40%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/eec-oss.png')}
                                                width="60%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/wasuthagroup.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                    <div className='col p-0 m-0' style={{ maxWidth: "max-content" }}>
                                        <button type="button" class="btn boxClient p-0">
                                            <img src={require('../../img/transcosmos.png')}
                                                width="50%"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="row p-0 bgredOurclient2">
                                <div className='col-sm p-0 bgredOurclient2' style={{ marginBottom: "12%", textAlign: "center" }}>
                                    <button type="button" class="btn boxClient p-0">
                                        <img src={require('../../img/logo_acfs.png')}
                                            width="40%"
                                        />
                                    </button>
                                    <button type="button" class="btn boxClient p-0">
                                        <img src={require('../../img/anti-money.png')}
                                            width="40%"
                                        />
                                    </button>
                                    <button type="button" class="btn boxClient p-0">
                                        <img src={require('../../img/eec-oss.png')}
                                            width="60%"
                                        />
                                    </button>
                                    <button type="button" class="btn boxClient p-0">
                                        <img src={require('../../img/wasuthagroup.png')}
                                            width="50%"
                                        />
                                    </button>
                                    <button type="button" class="btn boxClient p-0">
                                        <img src={require('../../img/transcosmos.png')}
                                            width="50%"
                                        />
                                    </button>
                                </div>
                            </div> */}


                        </div>
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Ourclients