import React from 'react'
import './Home.css'
import Ourservice from '../Ourservice/Ourservice';
import Ourclients from '../Ourclients/Ourclients';
import Aboutus from '../Aboutus/Aboutus';
import Contactus from '../Contactus/Contactus';
import Footer from '../Footer/Footer';
import Topbar from '../Topbar/Topbar';

const Home = () => {
    return (
        <div id="home">
            <Topbar />
            <div className="row justify-content-between navyblue-bg">
                <div className="col-6 sm-4 text-start box-heading">
                    <p className="redtext">A</p>
                    <p className="heading1 ">DEV SOLUTIONS PLUS</p>
                    <div className="col-6 text-start heading2">
                        MOVING<br />
                        TOGETHER
                    </div>
                </div>
                <div className="col-5 md-auto p-0 ">
                    <img className="imgheading" src={require('../../img/luca-bravo-XJXWbfSo2f0-unsplash.png')}
                        width="100%"
                        height="100%"
                        alt=""
                    />
                </div>

                <div className="row md-12 p-0 rectangle-bg1 justify-content-center ">
                    <div className="col d-flex textDetail topicTextSpace" style={{ paddingTop: "7%", paddingBottom: "3%" }}>
                        เราพัฒนาระบบ Web Application ช่วยบริหารจัดการ<br />
                        ข้อมูลต่างๆในการทำงานให้ชีวิตคุณง่ายขึ้น
                    </div>
                    <div className="row text-center mt-4 mb-4 p-0 SliderFiveIcon" style={{ backgroundColor: "White" }}>
                        <div class="mx-auto my-auto SliderHeight" style={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }} >

                            <span className="Prev-carousel" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev"><svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" fill="currentColor" class="bi bi-arrow-left-circle-fill icon-next" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                            </svg></span>

                            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" >
                                <div className="carousel-inner" >
                                    {/*row1 */}
                                    <div className="carousel-item py-0 active" >
                                        <div className="row d-flex justify-content-center" style={{ paddingTop: "1%" }}>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/Scg.png')}
                                                    width="60%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/loft.png')}
                                                    width="30%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/logo_PTT_3.png')}
                                                    width="50%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0m-1" style={{ marginBottom: "10" }}>
                                                <img src={require('../../img/meiji.png')}
                                                    width="60%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0 m-1">
                                                <img src={require('../../img/logo_icon.png')}
                                                    width="80%"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row2 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/Logo_SPW.png')}
                                                    width="50%"
                                                    style={{ marginTop: "10%" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/falcon.png')}
                                                    width="40%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/ananda.png')}
                                                    width="70%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/JSP.png')}
                                                    width="80%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/krungsri.png')}
                                                    width="80%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row3 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/ตรากรมสรรพสามิต.png')}
                                                    width="40%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/The-Agent.png')}
                                                    width="70%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/sansiri-public-Logo.png')}
                                                    width="70%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/MOCAP_03TH.png')}
                                                    width="45%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/one.png')}
                                                    width="60%"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row4 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/logo_acfs.png')}
                                                    width="40%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/anti-money.png')}
                                                    width="40%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/eec-oss.png')}
                                                    width="60%"
                                                    style={{ marginTop: "4%" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/wasuthagroup.png')}
                                                    width="60%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-sm col-2 p-0">
                                                <img src={require('../../img/transcosmos.png')}
                                                    width="60%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <span className="Next-carousel" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" fill="currentColor" class="bi bi-arrow-right-circle-fill icon-prev" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg></span>
                        </div>


                        <div className="row">
                            <div className="col" style={{ backgroundColor: "white" }}>
                                <a href="#ourClients"><button type="button" className="btn redbutton">See more</button></a>
                            </div>
                        </div>
                    </div>

                    {/* three icon*/}
                    <div className="row text-center mt-4 mb-4 p-0 SliderThreeIcon" style={{ backgroundColor: "White" }}>
                        <div class="mx-auto my-auto SliderHeight" style={{ display: "inline-flex", justifyContent: "center", alignItems: "center" }} >

                            <span className="Prev-carousel" type="button" data-bs-target="#carouselThreeIcon" data-bs-slide="prev"><svg xmlns="http://www.w3.org/2000/svg" width="40px" height="40px" fill="currentColor" class="bi bi-arrow-left-circle-fill icon-next" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                            </svg></span>

                            <div id="carouselThreeIcon" className="carousel slide" data-ride="carousel" >
                                <div className="carousel-inner" >
                                    {/*row1 */}
                                    <div className="carousel-item py-0 active" >
                                        <div className="row d-flex justify-content-center" style={{ paddingTop: "1%" }}>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/Scg.png')}
                                                    width="55%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/loft.png')}
                                                    width="25%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/logo_PTT_3.png')}
                                                    width="45%"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row2 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0 mt-3" style={{ marginBottom: "10" }}>
                                                <img src={require('../../img/meiji.png')}
                                                    width="55%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0 mt-3">
                                                <img src={require('../../img/logo_icon.png')}
                                                    width="75%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/Logo_SPW.png')}
                                                    width="45%"
                                                    style={{ marginTop: "10%" }}
                                                    alt=""
                                                />
                                            </div>

                                        </div>
                                    </div>
                                    {/*row3 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/falcon.png')}
                                                    width="35%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/ananda.png')}
                                                    width="65%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/JSP.png')}
                                                    width="75%"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row4 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/krungsri.png')}
                                                    width="75%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/ตรากรมสรรพสามิต.png')}
                                                    width="35%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/The-Agent.png')}
                                                    width="65%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row5 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/sansiri-public-Logo.png')}
                                                    width="65%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/MOCAP_03TH.png')}
                                                    width="40%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/one.png')}
                                                    width="55%"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row6 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/logo_acfs.png')}
                                                    width="35%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/anti-money.png')}
                                                    width="35%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/eec-oss.png')}
                                                    width="55%"
                                                    style={{ marginTop: "4%" }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*row7 */}
                                    <div className="carousel-item py-0">
                                        <div className="row d-flex justify-content-center">
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/wasuthagroup.png')}
                                                    width="60%"
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-4 p-0">
                                                <img src={require('../../img/transcosmos.png')}
                                                    width="60%"
                                                    style={{ marginTop: "6%" }}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <span className="Next-carousel" type="button" data-bs-target="#carouselThreeIcon" data-bs-slide="next">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="25px" fill="currentColor" class="bi bi-arrow-right-circle-fill icon-prev" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg></span>
                        </div>


                        <div className="row">
                            <div className="col" style={{ backgroundColor: "white" }}>
                                <a href="#ourClients"><button type="button" className="btn redbutton">See more</button></a>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="row p-0" style={{ color: "black", backgroundColor: "white" }}>
                    <div className="col-lg-4 col-md-6 col-12 bgredboxleft">
                        <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div className='row '>
                                <div className='col-md-4 col-6'>
                                    <div className="bgiconinbox">
                                        <img className='iconinbox' src={require('../../img/icon/appdev.png')}></img>
                                    </div>
                                </div>
                            </div>
                            <p className="topicText">App Development</p>
                            <div style={{ width: "92%" }}>ADEV ให้บริการด้านพัฒนาระบบ Mobile Software ทั้ง iOS, Android และอื่นๆ การพัฒนาที่มากกว่า Responsive สามารถตรวจสอบการประเมินตัวชี้วัด และเป็นมิตรต่อผู้ใช้ให้เราช่วยเพิ่มประสิทธิภาพให้ธุรกิจของคุณด้วยนักพัฒนาระบบ Mobile App ชั้นนำ
                            </div>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12 bgredboxmid">
                        <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div className='row'>
                                <div className='col-md-4 col-6 '>
                                    <div className="bgiconinbox">
                                        <img className='iconinbox' src={require('../../img/icon/iot.png')}></img>
                                    </div>
                                </div>
                            </div>
                            <p className="topicText">IOT</p>
                            <div style={{ width: "92%" }}>พัฒนาตู้คีออส เพื่อใช้งานในองค์กร ธุรกิจ ซึ่งคุณสามารถออกแบบฟังก์ชั่นต่าง ๆ ได้ตามต้องการ</div></p>
                    </div>
                    <div className="col-lg-4 col-12 bgredboxright">
                        <p className='boxDetail' style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div className='row'>
                                <div className='col-md-4 col-6 '>
                                    <div className="bgiconinbox">
                                        <img className='iconinbox' src={require('../../img/icon/webdesign.png')}></img>
                                    </div>
                                </div>
                            </div>
                            <p className="topicText">Web Design</p>
                            <div style={{ width: "92%" }}>
                                เราเป็นผู้ช่วยคุณในการขยายธุรกิจ เรานำเสนอการให้บริการด้าน Web Design อย่างครบครันเราใช้เทคนิคในการสร้าง SEO เพื่อให้ Website ของคุณอยู่ในอันดับที่ดีและเพิ่มจำนวนผู้เข้าชมให้แก่ Website ของคุณอย่างสูงสุด
                            </div>
                        </p>
                    </div>
                </div>

                <div className="row p-0">
                    <div className='col p-0'>
                        <img src={require('../../img/bg/BG_white_See more Service2.png')}
                            width="100%"
                            alt=""
                        />
                    </div>
                </div>

                <div className="row " >
                    <div className="col-md-5 d-flex align-items-center" style={{ paddingTop: '5%', paddingLeft: '5%' }}>
                        <div>
                            <p className='topicText'>Technologies & Frameworks</p>
                            <p className="normalEngText textBoxSize">InApp Specializes in full-lifecycle software
                                development services across a range of industries.
                                We take pride in being technology partners for the long
                                haul, committed to quality and innovation.</p></div>
                    </div>
                    <div className="col-md-7" style={{ paddingTop: '3%' }}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/NET_Logo.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/flutter-logo-768x768.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/java.png')}
                                    width="70%"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/php.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/Angular_full_color_logo.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/1_6kK9j74vyOmXYm1gN6ARhQ.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row rowReverse" >
                    <div className="col-md-7" style={{ paddingTop: '3%', paddingBottom: '2%' }}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 col-3  littlebox">
                                <img className='imgInLittleBox' src={require('../../img/android.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3  littlebox">
                                <img className='imgInLittleBox' style={{ paddingTop: '10%' }} src={require('../../img/ios.png')}
                                    width="50%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3  littlebox">
                                <img className='imgInLittleBox' style={{ paddingTop: '5%' }} src={require('../../img/windows.png')}
                                    width="100%"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/html.png')}
                                    width="47%"
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/jquery.png')}
                                    width="80%"
                                    style={{ paddingTop: "12px" }}
                                    alt=""
                                />
                            </div>
                            <div className="col-md-3 col-3 littlebox">
                                <img className='imgInLittleBox' src={require('../../img/Bootstrap.png')}
                                    width="80%"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 d-flex align-items-center textLeft textrightside" style={{ textAlign: 'right', paddingRight: '5%' }}>
                        <div>
                            <p className='topicText'>Products & Applications</p>
                            <p className="normalEngText textBoxSize">InApp Specializes in full-lifecycle software
                                development services across a range of industries.
                                We take pride in being technology partners for the long
                                haul, committed to quality and innovation.</p>
                        </div>
                    </div>
                </div>


            </div>
            <Ourservice />
            <Ourclients />
            <Aboutus />
            <Contactus />
            <Footer />
        </div >

    )
}

export default Home