import React, { useState } from 'react';
import './Topbar.css';


const Topbar = () => {

    const [menu, setMenu] = useState(
        [
            { id: "#home", name: "Home" },
            { id: "#ourService", name: "Our Service" },
            { id: "#ourClients", name: "Our Clients" },
            { id: "#aboutUs", name: "About Us" },
            { id: "#contectUs", name: "Contact Us" },
            { id: "private-policy", name: "Private Policy" },
        ]
    );

    const [activeTab, setactiveTab] = useState(0);

    return (
        // <nav class="navbar navbar-expand-lg navbar-light bg-light p-0 sticky-top">
        //     <div class="container-fluid">
        //         <a class="navbar-brand" href="#">Navbar</a>
        //         <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        //             <span class="navbar-toggler-icon"></span>
        //         </button>
        //         <div class="collapse navbar-collapse" id="navbarNav">
        //             <ul class="navbar-nav">
        //                 <li class="nav-item">
        //                     <a class="nav-link active" aria-current="page" href="#">Home</a>
        //                 </li>
        //                 <li class="nav-item">
        //                     <a class="nav-link" href="#">Features</a>
        //                 </li>
        //                 <li class="nav-item">
        //                     <a class="nav-link" href="#">Pricing</a>
        //                 </li>
        //                 <li class="nav-item">
        //                     <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        //                 </li>
        //             </ul>
        //         </div>
        //     </div>
        // </nav>


        <nav className="navbar navbar-expand-lg navbar-light bg-light p-0 sticky-top ">
            <div className="container-fluid p-0">
                    <img className="logo" src={require('../../img/logo_black.png')} style={{marginLeft:"50px"}}/>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon icon-bar"></span>
                </button>

                <div className="collapse navbar-collapse topbarright" id="navbarNav">
                    <ul className="navbar-nav" style={{marginLeft:"30px", marginBottom:"10px"}}>
                        {
                            menu.map((item, index) => {
                                return (
                                    <li className="nav-item"><a href={item.id} className={activeTab == index ? "nav-link link-dark active" : "nav-link link-dark"} aria-current="page" onClick={() => setactiveTab(index)}>{item.name}</a></li>
                                );
                            })
                        }
                    </ul>
                </div>
            </div>
        </nav>



        // <div className="container-fluid p-0 topbar">
        //     <div className="row justify-content-between align-items-center">
        //         <div className="col-lg-2 col-md-3 col-sm-4 col-4 text-center">
        //             <img className="logo" src={require('../../img/logo_black.png')} />
        //         </div>
        //         <div className="col-7 ">
        //             <ul className="nav col-md-auto topbarright">
        //                 {
        //                     menu.map((item, index) => {
        //                         return (
        //                             <li className="nav-item"><a href={item.id} className={activeTab == index ? "nav-link link-dark active" : "nav-link link-dark"} aria-current="page" onClick={() => setactiveTab(index)}>{item.name}</a></li>
        //                         );
        //                     })
        //                 }
        //             </ul>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Topbar