import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='navyblue-bg' style={{ paddingTop: "10%" }}>
            <div className='row black-bg' style={{ paddingTop: "3%", paddingBottom: "5%" }}>
                <div className='col-md littleredbox p-0'>
                    <div className='d-flex'>
                        <div className='col littleredbox2'>
                            <p className='headingText' style={{ paddingTop: "5%" }}>OFFICE HOURSE</p>
                            <p className='normalText'>Monday - Friday: 9AM - 6PM</p>
                            <p className='normalText' style={{padding: "0" }}>Saturday - Sunday: Closed</p>

                            <p className='normalText' style={{ paddingTop: "6%" }}>Adevsolutionsplus@gmail.com</p>
                            <p className='headingText'>Follow us</p>
                            
                            <a href="https://www.facebook.com/adevsolutionsplus/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-facebook" style={{ marginLeft: "5%", marginBottom: "5%" }} viewBox="0 0 16 16">
                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                            </svg></a>
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-3 col-sm-4 col-12'>
                    <div className='d-flex'>
                        <div className='col-2 d-flex justify-content-center'>
                            <img className='iconSize' src={require('../../img/icon/location.png')}
                            />
                        </div>
                        <div className='col normalText'>
                            Nonthaburi,Thailand<br />
                            66 Tambon Bang Rak Noi Mueang<br />
                            Nonthaburi 11000
                        </div>
                    </div>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12'>
                    <div className='d-flex'>
                        <div className='col-2 d-flex justify-content-center '>
                            <img className='iconSize' src={require('../../img/icon/phone.png')}
                            />
                        </div>
                        <div className='col normalText'>
                            087-495-4847<br />
                            Give us a call
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer